import { formatIncompletePhoneNumber } from 'libphonenumber-js';

export const stringCapitalized = (name: string) =>
  name.charAt(0).toLocaleUpperCase('nl-NL') + name.slice(1);

export const normalizePhoneValue = (value = '+') => {
  const normalizedValue = value && value.startsWith('+') ? value : `+${value}`;
  return formatIncompletePhoneNumber(normalizedValue);
};

export const arrayToStringRender = (value: string | Array<string>): string => {
  if (typeof value === 'string') return value;
  let result = '';
  value.forEach((valueStr: string, i: number) => {
    result += valueStr;

    if (i !== value.length - 1) {
      result += ', ';
    }
  });
  return result;
};

export const startsWithHTTP = (link: string): boolean => /^https?:\/\//.test(link);
