import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  position: relative;
  //display: flex;
  min-height: 120px;
  width: 100%;
  min-width: 0;
  padding: 24px;
  border-radius: 8px;

  border: 1px solid ${({ theme }) => theme.system.lightGrey};
  background-color: ${({ theme }) => theme.system.white};
`;

export const CardHeader = styled.div`
  display: flex;
`;
export const CardFooter = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  overflow: hidden;
`;

export const TextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.system.black};
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.system.darkGreyActive};
`;

export const CityName = styled.span`
  position: relative;
  min-width: 0;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  border-radius: 3px;

  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.system.black};

  padding-left: 24px;

  &:before {
    content: '';
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABOUExURQAAAICPn4+Pn4eHl4ePl4ePn4qKmoeLm4aMnImOnoaNnYmLnYqOn4eLnYmLnYqNnYqPn4iNnomNnomMnomMn4mOnoqMnomNnoqNnomNnkfDHFgAAAAZdFJOUwAQECAgIDBAUF9wcH+AgJ+fv7/Pz8/P3+8k1wBmAAAAZklEQVQYGW3BCRLCIBBFwU8kYlQStzh597+oDCKlVXbrr3C+wTrpYzQqi6qC0ViQO9LNcnfgsduvgMlRRGmgkNuAII0UcldgieECPOUy3SQ3bDQWVSWag5pMNavLFCd9SWZJv4LeXic1CwOjhHcUAAAAAElFTkSuQmCC');
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const EmploymentLabel = styled.span`
  flex: 0 0 auto;
  display: inline-block;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.system.lightGrey};

  color: ${({ theme }) => theme.brand.blue};

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const StyledLink = styled.a`
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
