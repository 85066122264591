import { FC, memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { APP_ROUTES } from 'constants/routes';

import {
  Container,
  Content,
  Grid,
  TextSection,
  H1,
  ImageSection,
  ImageWrapper,
  SubHeader,
  List,
  AuthButtonContainer,
  SignUpLink,
  SignIn,
  ImageAbstractTop,
  ImageAbstractLeft,
  ImageAbstractBottom,
  FeaturesContainer,
  FeaturesList,
  FeaturesListMobile,
} from './Hero.styled';

const Hero: FC = () => {
  return (
    <Container>
      <Content>
        <Grid>
          <TextSection>
            <H1>Student en geld verdienen?</H1>
            <SubHeader>
              Kies een leuke bijbaan tijdens je studententijd
            </SubHeader>
            <List>
              <li>Leukste studentenbanen</li>
              <li>+1000 nieuwe vacatures</li>
              <li>Iedere week uitbetaald</li>
            </List>
            <AuthButtonContainer>
              <Link passHref href={APP_ROUTES.SIGN_UP} prefetch={false}>
                <SignUpLink>Inschrijven</SignUpLink>
              </Link>
              <Link passHref href={APP_ROUTES.LOGIN} prefetch={false}>
                <SignIn>Log In</SignIn>
              </Link>
            </AuthButtonContainer>
          </TextSection>
          <ImageSection>
            <ImageWrapper>
              <Image
                priority
                width={549}
                height={409}
                layout="responsive"
                src="/assets/home-hero.webp"
                alt="Studenten banen via Studenten.nl"
                role="presentation"
              />
              <ImageAbstractTop />
              <ImageAbstractLeft />
              <ImageAbstractBottom />
            </ImageWrapper>
          </ImageSection>
          <FeaturesContainer>
            <FeaturesList>
              <li>Leukste studentenbanen</li>
              <li>+1000 nieuwe vacatures</li>
              <li>Iedere week uitbetaald</li>
            </FeaturesList>

            <FeaturesListMobile>
              <li>
                <b>+150 000</b> actuele vacatures van 500 bedrijven{' '}
              </li>
              <li>
                <b>15 000</b> kandidaten hebben banen gevonden
              </li>
            </FeaturesListMobile>
          </FeaturesContainer>
        </Grid>
      </Content>
    </Container>
  );
};

export default memo(Hero);
