import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  min-height: 116px;
  width: 100%;
  min-width: 0;
  padding: 12px 16px;
  border-radius: 16px;

  border: 1px solid ${({ theme }) => theme.system.lightGrey};
  background-color: ${({ theme }) => theme.system.white};
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  margin-right: 16px;
  width: 88px;
  height: 88px;
  border-radius: 8px;
  overflow: hidden;
`;

export const TextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.p`
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.system.black};
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.system.darkGreyActive};
`;

export const SalaryLabel = styled.span`
  display: inline-block;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  border-radius: 3px;
  background-color: #fdf2e5;

  color: ${({ theme }) => theme.system.black};
`;

export const StyledLink = styled.a`
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
