import { FC, memo } from 'react';
import Link from 'next/link';

import { ImageWithFallback } from './components/ImageWithFallback';

import placeholder from 'public/assets/placeholders/placeholder-6.svg';

import {
  Container,
  Content,
  ImageWrapper,
  TextWrapper,
  Title,
  Description,
  StyledLink,
  SalaryLabel,
} from './JobMiniCard.styled';

export type JobMiniCardProps = {
  img?: string;
  title: string;
  href: string;
  description?: string;
  salary?: string;
};

const JobMiniCard: FC<JobMiniCardProps> = ({
  title,
  description,
  salary,
  href,
  img = placeholder,
}) => {
  return (
    <Container>
      <Content>
        <ImageWrapper>
          <ImageWithFallback
            unoptimized
            src={img}
            fallbackSrc={placeholder}
            layout="fill"
            objectFit="cover"
            alt=""
            role="presentation"
          />
        </ImageWrapper>
        <TextWrapper>
          <div>
            <Title>
              <Link passHref href={href}>
                <StyledLink>{title}</StyledLink>
              </Link>
            </Title>
            {description ? <Description>{description}</Description> : null}
          </div>
          {salary ? (
            <div>
              <SalaryLabel>{salary}</SalaryLabel>
            </div>
          ) : null}
        </TextWrapper>
      </Content>
    </Container>
  );
};

export default memo(JobMiniCard);
