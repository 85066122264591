import { FC, memo } from 'react';
import Link from 'next/link';

import { ImageWithFallback } from './components/ImageWithFallback';

import placeholder from 'public/assets/placeholders/placeholder-19.svg';

import {
  Container,
  Content,
  ImageWrapper,
  TextWrapper,
  Title,
  Description,
  StyledLink,
  CardFooter,
  CardHeader,
  CityName,
  EmploymentLabel,
} from './VacancyMiniCard.styled';
import { arrayToStringRender } from '../../../../../../../../lib/strings';

export type JobMiniCardProps = {
  img?: string;
  title: string;
  href: string;
  company?: string;
  city?: string | string[];
  employment?: string | string[];
};

const VacancyMiniCard: FC<JobMiniCardProps> = ({
  title,
  company,
  city,
  href,
  employment,
  img = placeholder,
}) => {
  const showFooter = !!(city || employment);

  return (
    <Container>
      <Content>
        <CardHeader>
          <ImageWrapper>
            <ImageWithFallback
              unoptimized={img !== placeholder}
              src={img}
              fallbackSrc={placeholder}
              layout="fill"
              objectFit="cover"
              alt=""
              role="presentation"
            />
          </ImageWrapper>
          <TextWrapper>
            <Title>
              <Link passHref href={href}>
                <StyledLink>{title}</StyledLink>
              </Link>
            </Title>
            {company ? <Description>{company}</Description> : null}
          </TextWrapper>
        </CardHeader>
        {showFooter && (
          <CardFooter>
            {city ? <CityName>{arrayToStringRender(city)}</CityName> : null}
            {employment?.length ? (
              <EmploymentLabel>{arrayToStringRender(employment)}</EmploymentLabel>
            ) : null}
          </CardFooter>
        )}
      </Content>
    </Container>
  );
};

export default memo(VacancyMiniCard);
