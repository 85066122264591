import { FC, memo } from 'react';
import Link from 'next/link';

import { YTEmbeddedVideo } from 'src/common/YTEmbeddedVideo';
import { EmbeddedYouTube, LinkComponent } from 'api_entities/strapi/types';

import {
  Container,
  Content,
  FirstColumn,
  Grid,
  H2,
  PlayerWrapper,
  SecondColumn,
  Text,
  StyledLink,
  StyledImageLeftBottom,
  StyledImageLeftTop,
  StyledImageRightTop,
} from './LearnWithUs.styled';

export type LearnWithUsProps = {
  title: string;
  description?: string;
  link?: LinkComponent;
  video: EmbeddedYouTube;
};

const LearnWithUs: FC<LearnWithUsProps> = ({ title, description, link, video }) => {
  return (
    <Container>
      <Content>
        <StyledImageLeftTop role="presentation" />
        <StyledImageRightTop role="presentation" />
        <Grid>
          <FirstColumn>
            <div>
              <H2>{title}</H2>
              <Text>{description}</Text>
            </div>
            <div>
              {!!link && (
                <Link passHref href={link.href}>
                  <StyledLink>{link.label}</StyledLink>
                </Link>
              )}
            </div>
          </FirstColumn>
          <SecondColumn>
            <PlayerWrapper>
              <YTEmbeddedVideo
                title={video.title || ''}
                ytVideoId={video.videoId}
                coverWidth={1920}
                coverHeight={1080}
              />
            </PlayerWrapper>
            {!!link && (
              <Link passHref href={link.href}>
                <StyledLink>{link.label}</StyledLink>
              </Link>
            )}
          </SecondColumn>
        </Grid>
        <StyledImageLeftBottom role="presentation" />
      </Content>
    </Container>
  );
};

export default memo(LearnWithUs);
