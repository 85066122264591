import { FC, memo, useCallback, useEffect, useState } from 'react';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import { Icons } from '@studenten/ui-components';

import { LinkComponent } from 'api_entities/strapi/types';

import { CategoryCard } from './components/CategoryCard';

import {
  Container,
  Content,
  Header,
  H2,
  Slider,
  Slide,
  SliderButton,
  VisuallyHidden,
  StyledDesktopSliderControls,
  StyledMobileSliderControls,
  SliderContainer,
  SliderWrapper,
} from './StudentInstruments.styled';

export type StudentInstrumentsProps = {
  title: string;
  isDark?: boolean;
  links: LinkComponent[];
};

const emblaOptions: EmblaOptionsType = {
  loop: false,
  align: 'start',
  containScroll: 'trimSnaps',
  speed: 20,
  inViewThreshold: 1,
};

const StudentInstruments: FC<StudentInstrumentsProps> = ({ title, links, isDark }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(emblaOptions);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);
  const onSelect = useCallback(() => {
    setPrevBtnEnabled(!!emblaApi?.canScrollPrev());
    setNextBtnEnabled(!!emblaApi?.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    emblaApi?.on('select', onSelect);
    onSelect();
  }, [emblaApi]);

  return (
    <Container isDark={isDark}>
      <Content>
        <Header isDark={isDark}>
          <Icons.BookmarkBigIcon />
          <H2>{title}</H2>
          <StyledDesktopSliderControls>
            <SliderButton isDark={isDark} disabled={!prevBtnEnabled} onClick={scrollPrev}>
              <Icons.LeftArrow />
              <VisuallyHidden>previous slide</VisuallyHidden>
            </SliderButton>
            <SliderButton isDark={isDark} disabled={!nextBtnEnabled} onClick={scrollNext}>
              <Icons.RightArrow />
              <VisuallyHidden>next slide</VisuallyHidden>
            </SliderButton>
          </StyledDesktopSliderControls>
        </Header>

        {links?.length && (
          <SliderWrapper>
            <Slider ref={emblaRef}>
              <SliderContainer>
                {links.map(({ label, id, href, isExternal }) => (
                  <Slide key={id}>
                    <CategoryCard href={href} title={label} isExternalLink={isExternal} />
                  </Slide>
                ))}
              </SliderContainer>
            </Slider>
          </SliderWrapper>
        )}

        <StyledMobileSliderControls>
          <SliderButton isDark={isDark} disabled={!prevBtnEnabled} onClick={scrollPrev}>
            <Icons.LeftArrow />
            <VisuallyHidden>previous slide</VisuallyHidden>
          </SliderButton>
          <SliderButton isDark={isDark} disabled={!nextBtnEnabled} onClick={scrollNext}>
            <Icons.RightArrow />
            <VisuallyHidden>next slide</VisuallyHidden>
          </SliderButton>
        </StyledMobileSliderControls>
      </Content>
    </Container>
  );
};

export default memo(StudentInstruments);
