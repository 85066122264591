import { FC, memo } from 'react';
import Link from 'next/link';

import { JobSearchComponent } from 'api_entities/strapi/types';

import {
  Container,
  Content,
  H2,
  CategoryLink,
  CategoryTag,
  CategoryTagList,
  Wrapper,
} from './PopularCategories.styled';

export type PopularCategoriesProps = {
  jobSearch: JobSearchComponent[];
};

export const PopularCategories: FC<PopularCategoriesProps> = ({ jobSearch }) => {
  return (
    <Container>
      <Content>
        {jobSearch.map(({ title, pageLinks, showMore }) => (
          <Wrapper key={title}>
            <H2>{title}</H2>
            <CategoryTagList>
              {pageLinks.map(({ label, href }) => (
                <li key={label}>
                  <Link passHref href={href}>
                    <CategoryTag>{label}</CategoryTag>
                  </Link>
                </li>
              ))}
            </CategoryTagList>
            {showMore && (
              <Link passHref href={showMore.href}>
                <CategoryLink>{showMore.label}</CategoryLink>
              </Link>
            )}
          </Wrapper>
        ))}
      </Content>
    </Container>
  );
};

export default memo(PopularCategories);
