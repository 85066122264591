import { FC, memo, useState, useEffect } from 'react';
import Image, { ImageProps } from 'next/image';

export type ImageWithFallback = {
  fallbackSrc?: ImageProps['src'];
} & ImageProps;

const ImageWithFallback: FC<ImageWithFallback> = ({ fallbackSrc, src, ...props }) => {
  const [_src, setSrc] = useState<ImageProps['src']>(src);

  useEffect(() => {
    setSrc(src);
  }, [src]);

  const loadingCompleteHandler: ImageProps['onLoadingComplete'] = (result) => {
    if (result.naturalWidth === 0 && fallbackSrc) {
      // Broken image
      setSrc(fallbackSrc);
    }
  };

  const errorHandler: ImageProps['onError'] = () => {
    if (fallbackSrc) {
      // Broken image
      setSrc(fallbackSrc);
    }
  };

  return (
    <Image
      {...props}
      src={_src}
      onLoadingComplete={loadingCompleteHandler}
      onError={errorHandler}
    />
  );
};

export default memo(ImageWithFallback);
