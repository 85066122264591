import { GetStaticProps } from 'next';
import homePage from 'api_entities/strapi/homePage';
import { vacanciesApi } from 'api_entities/vacancies';
import { APP_ROUTES } from 'constants/routes';
import { logger } from '../utils/logger';

import { Home } from '../features/other/templates/Home';
import { FacetedResponse } from '../api_entities/types';
import { Job } from '../api_entities/vacancies/types';
import { HomePageContent } from '../api_entities/strapi/types';

export const getStaticProps: GetStaticProps = async () => {
  let latestJobs: FacetedResponse<Job> | null;
  let strapiContent: HomePageContent | null;

  try {
    const params = new URLSearchParams();
    params.append('limit', '3');

    const [_latestJobs, _strapiContent] = await Promise.allSettled([
      vacanciesApi.getJobs(params),
      homePage.fetchData(),
    ]);

    latestJobs = _latestJobs.status === 'fulfilled' ? _latestJobs.value : null;
    strapiContent = _strapiContent.status === 'fulfilled' ? _strapiContent.value : null;

    const latestJobsProps = latestJobs?.data?.map(
      ({ title, city, slug, companyName, photoURL, type }) => ({
        title,
        city,
        employment: type,
        company: companyName,
        img: photoURL,
        href: `${APP_ROUTES.ALL_VACANCIES}/${slug}`,
      })
    );

    return {
      props: {
        ...(latestJobsProps && { latestJobsProps }),
        ...(strapiContent && { strapiContent }),
      },
      revalidate: 60,
    };
  } catch (e) {
    logger.error(e);
    logger.warn('Failed to render a "Home page".');

    return {
      notFound: true,
    };
  }
};

export default Home;
