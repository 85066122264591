import styled from 'styled-components';

import { device } from 'constants/device';

export const Container = styled.section<{ isDark?: boolean }>`
  width: 100%;
  background-color: ${({ theme, isDark }) => (isDark ? theme.brand.blue : theme.system.lightGrey)};
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 108px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  min-height: 438px;

  @media ${device.laptop} {
    min-height: 312px;
    padding: 30px 58px;
  }
  @media ${device.mobileL} {
    padding: 30px 16px;
  }
`;

export const Header = styled.div<{ isDark?: boolean }>`
  position: relative;
  width: 100%;
  padding-right: 144px;
  display: flex;
  align-items: center;

  color: ${({ theme, isDark }) => (isDark ? theme.system.white : theme.brand.blue)};

  svg {
    margin-right: 16px;

    @media ${device.laptop} {
      width: 24px;
      height: 24px;

      margin-right: 8px;
    }
  }

  @media ${device.laptop} {
    padding: 0;
  }
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.2rem;
  color: inherit;

  @media ${device.laptop} {
    font-size: 2rem;
    line-height: 2.6rem;
  }
`;

export const SliderWrapper = styled.div`
  margin: 24px 0;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
`;

export const Slider = styled.div`
  overflow: hidden;
`;

export const SliderContainer = styled.div`
  display: flex;
`;

export const Slide = styled.div`
  display: flex;
  flex: 0 0 290px;
  margin-right: 24px;
`;

export const SliderControls = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.laptop} {
    display: none;

    button {
      width: 56px;
      height: 56px;
    }
  }
`;

export const SliderButton = styled.button<{ isDark?: boolean }>`
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 8px;

  border: 1px solid ${({ theme, isDark }) => (isDark ? theme.system.white : theme.system.lightGrey)};
  background-color: ${({ theme, isDark }) => (isDark ? theme.brand.blue : theme.system.white)};

  &:last-of-type {
    margin-right: 0;
  }

  span {
    border-color: ${({ theme, isDark }) =>
      isDark ? theme.system.white : theme.system.darkGreyActive};
  }

  &:not([disabled]):hover {
    cursor: pointer;
    border-color: ${({ theme, isDark }) =>
      isDark ? theme.system.lightGrey : theme.system.darkGreyActive};

    span {
      border-color: ${({ theme, isDark }) =>
        isDark ? theme.system.lightGrey : theme.system.darkGreyActive};
    }
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.system.lightGrey};

    & {
      cursor: not-allowed;
    }
  }
`;

export const VisuallyHidden = styled.span`
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  position: absolute;
`;

export const StyledDesktopSliderControls = styled(SliderControls)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

export const StyledMobileSliderControls = styled(SliderControls)`
  display: none;
  margin: 0 auto;

  @media ${device.laptop} {
    display: flex;

    button {
      width: 40px;
      height: 40px;
    }
  }
`;
