import styled from 'styled-components';
import { device } from 'constants/device';

export const Container = styled.section`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  padding: 0 108px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  min-height: 600px;

  @media ${device.laptop} {
    padding: 0 58px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 26px 0;

  @media ${device.laptop} {
    padding: 40px 0;
  }
`;

export const HeaderWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.2rem;
  margin-bottom: 12px;

  color: ${({ theme }) => theme.brand.blue};

  @media ${device.laptop} {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;

  color: ${({ theme }) => theme.brand.blue};

  @media ${device.laptop} {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
`;

export const List = styled.ul`
  width: 100%;
  margin: 0 auto;
  padding: 24px 0;
  display: grid;
  gap: 24px 16px;
  grid-template-columns: repeat(3, 1fr);

  @media ${device.laptopL} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }

  li {
    display: flex;
  }
`;

export const ActionsWrapper = styled.div`
  margin: 30px auto;
`;

export const PageLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  border: 1px solid ${({ theme }) => theme.brand.orange};
  border-radius: 9px;
  transition: all 0.3s ease-in;

  background-color: ${({ theme }) => theme.system.white};
  color: ${({ theme }) => theme.brand.orange};

  &:hover {
    border-color: ${({ theme }) => theme.brand.darkOrange};
    color: ${({ theme }) => theme.brand.darkOrange};
  }
`;
