import { FC, memo } from 'react';
import Link from 'next/link';

import { APP_ROUTES } from 'constants/routes';

import { VacancyMiniCard } from './components/VacancyMiniCard';
import { JobMiniCardProps } from './components/VacancyMiniCard/VacancyMiniCard';

import {
  Container,
  Content,
  H2,
  Wrapper,
  ActionsWrapper,
  List,
  PageLink,
  Text,
  HeaderWrapper,
} from './TrendingVacancies.styled';

export type TrendingVacancies = {
  jobs: JobMiniCardProps[];
}

const TrendingVacancies: FC<TrendingVacancies> = ({ jobs }) => {
  return (
    <Container>
      <Content>
        <Wrapper>
          <HeaderWrapper>
            <H2>Studenten Bijbanen</H2>
            <Text>
              Wij hebben op dit moment 1000+ leuke studenten bijbanen. Schrijf je
              in en vind de bijbaan die bij je past!
            </Text>
          </HeaderWrapper>
          <List>
            {jobs.map(({ title, company, employment, href, city }, idx) => (
              <li key={idx}>
                <VacancyMiniCard
                  title={title}
                  company={company}
                  city={city}
                  employment={employment}
                  href={href}
                />
              </li>
            ))}
          </List>
          <ActionsWrapper>
            <Link passHref href={APP_ROUTES.SIGN_UP}>
              <PageLink>Inschrijven</PageLink>
            </Link>
          </ActionsWrapper>
        </Wrapper>
      </Content>
    </Container>
  );
};

export default memo(TrendingVacancies);
