import styled from 'styled-components';

export const Card = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.system.white};
  border: 1px solid ${({ theme }) => theme.system.lightGrey};
  border-radius: 18px;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: auto;
  height: 77px;
  border-radius: 10px;
  overflow: hidden;
`;

export const StyledLink = styled.a`
  margin-top: 12px;
  width: 100%;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-right: 24px;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.system.black};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    display: block;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAhUExURQAAAEBAQDhASD4+Rjo9RTw+Rzs9RDw+Rjw+RD0+RTw+RU2+QpgAAAAKdFJOUwAQIF9gb3B/gN/5YJYoAAAANElEQVQI12NgYGBgCmCAAMnlUAbLqgIoy4uA0CoIWA5jrIBIMa9qgDCsCApwQgUYmBKABAB2DxdI8waJkgAAAABJRU5ErkJggg==');
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;
