import { FC, memo, useCallback, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

import { useUpToLaptop } from 'hooks/useMediaQuery';

import { Container, Content, H2, ListWrapper } from './HowItWorks.styled';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StepListDesktop = dynamic(() => import('./components/StepListDesktop').then((mod) => mod.StepListDesktop), { ssr: false });
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StepListMobile = dynamic(() => import('./components/StepListMobile').then((mod) => mod.StepListMobile), { ssr: false });

const HowItWorks: FC = () => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const isMatched = useUpToLaptop();

  const handleContainerRef = useCallback((node: HTMLDivElement) => {
    setContainerRef(node);
  }, []);

  useEffect(() => {
    let observer: IntersectionObserver;

    if (containerRef) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry?.intersectionRatio > 0) {
            setShowAnimation(true);
          } else {
            if (showAnimation) {
              setShowAnimation(false);
            }
          }
        },
        {
          root: null,
          threshold: 0,
          rootMargin: '0px'
        }
      );

      observer.observe(containerRef);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [containerRef]);

  return (
    <Container ref={handleContainerRef}>
      <Content>
        <H2>Hoe Werkt Het</H2>
        <ListWrapper>
          {isMatched ? (
            <StepListMobile />
          ) : (
            <StepListDesktop
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              showAnimation={showAnimation}
            />
          )}
        </ListWrapper>
      </Content>
    </Container>
  );
};

export default memo(HowItWorks);
