import styled from 'styled-components';
import { device } from 'constants/device';

export const Container = styled.section`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  padding: 0 108px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  min-height: 560px;

  @media ${device.laptop} {
    padding: 0 58px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 80px 0;

  @media ${device.laptop} {
    padding: 40px 0;
  }
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 4rem;
  line-height: 5.6rem;
  margin-bottom: 12px;

  color: ${({ theme }) => theme.brand.blue};

  @media ${device.laptop} {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 8px;
  }
`;

export const List = styled.ul`
  margin: 20px 0;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);

  @media ${device.laptopL} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(1, 1fr);
  }

  li {
    display: flex;
  }
`;

export const ActionsWrapper = styled.div`
  margin: auto;
`;

export const PageLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  border-radius: 9px;
  transition: color 0.2s ease-in;

  background-color: ${({ theme }) => theme.brand.orange};
  color: ${({ theme }) => theme.system.white};

  &:hover {
    background-color: ${({ theme }) => theme.brand.darkOrange};
  }
`;
