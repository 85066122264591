import { FC, memo } from 'react';
import Link from 'next/link';

import { LinkComponent } from 'api_entities/strapi/types';
import { useUpToLaptop } from 'hooks/useMediaQuery';

import { JobMiniCard } from './components/JobMiniCard';

import {
  Container,
  Content,
  H2,
  Wrapper,
  ActionsWrapper,
  List,
  PageLink,
} from './TrendingJobs.styled';

const MAX_JOBS_TO_RENDER = 10;

export type TrendingJobsProps = {
  title: string;
  jobs: Array<{
    name: string;
    href: string;
    description: string;
    salary: string;
  }>;
  link?: LinkComponent;
};

const TrendingJobs: FC<TrendingJobsProps> = ({ title, jobs, link }) => {
  const isMobile = useUpToLaptop();

  return (
    <Container>
      <Content>
        <Wrapper>
          <H2>{title}</H2>
          <List>
            {jobs.map(({ name, description, salary, href }, idx) => {
              if (isMobile && idx > MAX_JOBS_TO_RENDER - 1) {
                return null;
              }

              return (
                <li key={name}>
                  <JobMiniCard title={name} description={description} salary={salary} href={href} />
                </li>
              );
            })}
            {!!link && (
              <li>
                <ActionsWrapper>
                  <Link passHref href={link.href}>
                    <PageLink>{link.label}</PageLink>
                  </Link>
                </ActionsWrapper>
              </li>
            )}
          </List>
        </Wrapper>
      </Content>
    </Container>
  );
};

export default memo(TrendingJobs);
