import { FC, memo } from 'react';
import Link from 'next/link';

import { ImageWithFallback } from './components/ImageWithFallback';

import placeholder from 'public/assets/placeholders/placeholder-20.svg';

import { Card, Content, ImageWrapper, StyledLink } from './CategoryCard.styled';

export type CategoryCardProps = {
  title: string;
  href: string;
  img?: string;
  isExternalLink?: boolean;
};

export const CategoryCard: FC<CategoryCardProps> = ({
  title,
  href,
  isExternalLink,
  img = placeholder,
}) => {
  return (
    <Card>
      <Content>
        <ImageWrapper>
          <ImageWithFallback
            unoptimized={img !== placeholder}
            src={img}
            fallbackSrc='/assets/placeholders/placeholder-20.svg'
            layout="fill"
            objectFit="cover"
            role="presentation"
            alt=""
          />
        </ImageWrapper>
        {isExternalLink ? (
          <StyledLink href={href} rel="noopener noreferrer">
            {title}
          </StyledLink>
        ) : (
          <Link passHref href={href}>
            <StyledLink>{title}</StyledLink>
          </Link>
        )}
      </Content>
    </Card>
  );
};

export default memo(CategoryCard);
