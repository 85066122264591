import { FC, memo } from 'react';
import Head from 'next/head';

import Layout from 'features/layout/templates/Layout';
import { MetaTags } from 'src/common/Seo';
import { Hero } from './components/Hero';
import { CheckOutJobs } from 'src/components/CheckOutJobs';
import { FAQ } from 'src/components/FAQ';
import { LearnWithUs } from './components/LearnWithUs';
import { PopularCategories } from './components/PopularCategories';
import { HowItWorks } from './components/HowItWorks';
import { TrendingJobs } from './components/TrendingJobs';
import { TrendingVacancies } from './components/TrendingVacancies';
import { StudentInstruments } from 'src/components/StudentInstruments';

import type { Props } from './types';

const websiteSchema = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: 'Studenten.nl BV',
  url: 'https://studenten.nl/',
  description: 'Studenten.nl, official website',
  inLanguage: 'NL',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://studenten.nl/vacatures?query={search_term}',
    'query-input': 'required name=search_term',
  },
  publisher: {
    '@type': 'Organization',
    name: 'Studenten.nl BV',
  },
  creator: {
    '@type': 'Organization',
    name: 'Studenten.nl BV',
  },
});

const organizationSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Studenten.nl BV',
  url: 'https://studenten.nl/',
  logo: 'https://studenten.nl/assets/studenten-og.png',
  description: 'Temp agency in the Netherlands.',
  email: 'admin@studenten.nl',
  telephone: '+31302272133',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Lange Viestraat 2b',
    addressRegion: 'Utrecht',
    postalCode: '3511 BK',
    addressCountry: 'NL',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer service',
    email: 'support@studenten.nl',
  },
  sameAs: [
    'https://www.facebook.com/www.studenten.nl',
    'https://www.x.com/Studentennl',
    'https://www.youtube.com/@studentenuitzendbureau',
    'https://www.instagram.com/studenten.nl_official/',
  ],
});

const Home: FC<Props> = ({ strapiContent, latestJobs }) => {
  const { attributes } = strapiContent || {};
  const { seoMeta, footerCta, faq, studentsTools, videoCta, trendJobs, jobSearchPages } =
    attributes || {};

  const accordionData = faq?.items.map(({ question: header, answer: text, buildSchemaJson }) => ({
    header,
    text,
    buildSchemaJson,
  }));

  return (
    <>
      <Head>
        <script
          id="website-ld+json"
          key="website-ld+json"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: websiteSchema }}
        />
        <script
          type="application/ld+json"
          id="organization-ld+json"
          dangerouslySetInnerHTML={{ __html: organizationSchema }}
        />
      </Head>
      <MetaTags title={seoMeta?.title} description={seoMeta?.description} canonicalUrl="/" />
      <Layout>
        <Hero />
        {!!jobSearchPages?.length && <PopularCategories jobSearch={jobSearchPages} />}
        {!!trendJobs && (
          <TrendingJobs title={trendJobs.title} jobs={trendJobs.jobs} link={trendJobs.showMore} />
        )}
        <HowItWorks />
        {!!videoCta && (
          <LearnWithUs
            title={videoCta.title}
            description={videoCta.description}
            link={videoCta.link}
            video={videoCta.video}
          />
        )}
        {!!latestJobs?.length && <TrendingVacancies jobs={latestJobs} />}
        {!!faq && accordionData?.length && <FAQ title={faq.title} accordionData={accordionData} />}
        {!!studentsTools && (
          <StudentInstruments title={studentsTools.title} links={studentsTools.links} />
        )}
        {!!footerCta && <CheckOutJobs title={footerCta.title} link={footerCta.link} />}
      </Layout>
    </>
  );
};

export default memo(Home);
