import { FC, useMemo, useState, useEffect, ComponentPropsWithoutRef } from 'react';
import {
  isFirefox,
  isMobileSafari,
  isMobileOnly,
  isTablet,
  isChrome,
  isSafari,
} from 'react-device-detect';

interface PlayerProps extends Omit<ComponentPropsWithoutRef<'video'>, 'children'> {
  type?: string;
}

export const Player: FC<PlayerProps> = ({
  src: video,
  muted = true,
  controls = true,
  type = 'video/mp4',
  ...elementProps
}) => {
  const [hasMounted, setMounted] = useState<boolean>(false);

  const src = useMemo(() => {
    if (!hasMounted) {
      return `${video}#t=0.001`;
    }

    return isMobileSafari || isSafari || isFirefox || isChrome ? video : `${video}#t=0.001`;
  }, [hasMounted, video, isFirefox, isMobileSafari]);

  const videoProps = useMemo(() => {
    if (!hasMounted) {
      return {};
    }

    return isChrome && (isMobileOnly || isTablet) ? { preload: 'metadata' } : {};
  }, [hasMounted, src]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <video src={src} controls={controls && !!src} muted={muted} {...videoProps} {...elementProps}>
      <source src={video} type={type} />
      Your browser does not support HTML5 video.
    </video>
  );
};
